<template>
  <div class="loan-auth-container">
    <van-nav-bar class="nav-bar" title="贷款申请" left-arrow @click-left="onClickLeft" :border="false" fixed />

    <div class="progress-section">
      <van-steps :active="active" active-color="#1989fa">
        <van-step>基本资质</van-step>
        <van-step>信用信息</van-step>
        <van-step>个人信息</van-step>
      </van-steps>
    </div>

    <div class="form-container">
      <template v-if="active === 0">
        <van-cell-group inset class="form-group">
          <van-cell title="所在地区" :value="areaText || '请选择'" is-link @click="showArea" />

          <van-field label="工资发放形式" readonly>
            <template #input>
              <van-radio-group v-model="form.monthlyIncomeType" direction="horizontal">
                <van-radio name="现金">现金</van-radio>
                <van-radio name="银行卡">银行卡</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field label="月收入范围" readonly>
            <template #input>
              <div class="custom-select" @click="showIncomePicker = true">
                {{ form.monthlyIncome || '请选择' }}
              </div>
            </template>
          </van-field>

          <van-field label="当前工龄" readonly>
            <template #input>
              <van-radio-group v-model="form.workedMonth" direction="horizontal">
                <van-radio name="半年以下">半年以下</van-radio>
                <van-radio name="半年-1年">半年-1年</van-radio>
                <van-radio name="1年以上">1年以上</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field label="本地公积金" readonly>
            <template #input>
              <van-radio-group v-model="form.providentFund" direction="horizontal">
                <van-radio name="有">有</van-radio>
                <van-radio name="无">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field label="本地社保" readonly>
            <template #input>
              <van-radio-group v-model="form.socialSecurity" direction="horizontal">
                <van-radio name="有">有</van-radio>
                <van-radio name="无">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field label="房产情况" readonly>
            <template #input>
              <van-radio-group v-model="form.house" direction="horizontal">
                <van-radio name="有房产">有房产</van-radio>
                <van-radio name="无房产">无房产</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field label="名下车辆" readonly>
            <template #input>
              <van-radio-group v-model="form.car" direction="horizontal">
                <van-radio name="有">有车</van-radio>
                <van-radio name="无">无车</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-cell-group>
      </template>

      <template v-if="active === 1">
        <van-cell-group inset class="form-group">
          <van-field label="芝麻信用分" readonly>
            <template #input>
              <div class="custom-select" @click="showZhimaPicker = true">
                {{ form.zhima || '请选择' }}
              </div>
            </template>
          </van-field>

          <van-field label="微粒贷额度" readonly>
            <template #input>
              <div class="custom-select" @click="showLoanPicker = true">
                {{ form.tencentLoan || '请选择' }}
              </div>
            </template>
          </van-field>

          <van-field label="保险保单" readonly>
            <template #input>
              <div class="custom-select" @click="showInsurancePicker = true">
                {{ form.insurance || '请选择' }}
              </div>
            </template>
          </van-field>
        </van-cell-group>
      </template>

      <template v-if="active === 2">
        <van-cell-group inset class="form-group">
          <van-field v-model="form.realName" label="真实姓名" placeholder="请输入真实姓名"
            :rules="[{ required: true, message: '请输入真实姓名' }]" maxlength="4" />
          <van-field v-model="form.idCard" label="身份证号码" placeholder="请输入身份证号码"
            :rules="[{ required: true, message: '请输入身份证号码' }]" maxlength="18" />
          <van-field v-model="form.applyMoney" label="贷款金额" placeholder="请输入预计贷款金额" type="number" maxlength="6"
            :rules="[{ required: true, message: '请输入贷款金额' }]">
            <template #button>
              <span class="unit">元</span>
            </template>
          </van-field>
        </van-cell-group>
      </template>
    </div>

    <div class="bottom-button">
      <van-button type="primary" block round color="#1989fa" :loading="loading" @click="next">
        {{ active === 2 ? '提交申请' : '下一步' }}
      </van-button>
    </div>

    <van-popup v-model="showAreaPicker" position="bottom" round>
      <van-area :area-list="areaList" @confirm="onConfirmArea" @cancel="showAreaPicker = false" :columns-num="2" />
    </van-popup>

    <van-popup v-model="showIncomePicker" position="bottom" round>
      <van-picker :columns="incomeColumns" @confirm="onConfirmIncome" @cancel="showIncomePicker = false" show-toolbar
        title="选择月收入范围" />
    </van-popup>

    <van-popup v-model="showZhimaPicker" position="bottom" round>
      <van-picker :columns="zhimaColumns" @confirm="onConfirmZhima" @cancel="showZhimaPicker = false" show-toolbar
        title="选择芝麻分范围" />
    </van-popup>

    <van-popup v-model="showLoanPicker" position="bottom" round>
      <van-picker :columns="loanColumns" @confirm="onConfirmLoan" @cancel="showLoanPicker = false" show-toolbar
        title="选择微粒贷额度" />
    </van-popup>

    <van-popup v-model="showInsurancePicker" position="bottom" round>
      <van-picker :columns="insuranceColumns" @confirm="onConfirmInsurance" @cancel="showInsurancePicker = false"
        show-toolbar title="选择保险保单情况" />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data'
import { checkIdCardIsOk, checkNameIsOk } from '@/utils/tools'
import { saveLoanInfo } from '@/api/user'

export default {
  name: 'Auth',
  data() {
    return {
      form: {
        cityCode: '',
        cityName: '',
        provCode: '',
        provName: '',
        monthlyIncomeType: '',
        monthlyIncome: '',
        workedMonth: '',
        providentFund: '',
        socialSecurity: '',
        house: '',
        car: '',
        zhima: '',
        tencentLoan: '',
        insurance: '',
        realName: '',
        idCard: '',
        applyMoney: ''
      },
      active: 0,
      showAreaPicker: false,
      areaList,
      areaText: '',
      loading: false,
      showIncomePicker: false,
      incomeColumns: [
        { text: '5000以下', value: '5000以下' },
        { text: '5000-10000', value: '5000-10000' },
        { text: '1万以上', value: '1万以上' }
      ],
      showZhimaPicker: false,
      zhimaColumns: [
        { text: '无芝麻分', value: '无芝麻分' },
        { text: '600分以下', value: '600分以下' },
        { text: '600-650分', value: '600-650分' },
        { text: '650-700分', value: '650-700分' },
        { text: '700分以上', value: '700分以上' }
      ],
      showLoanPicker: false,
      loanColumns: [
        { text: '无额度', value: '无额度' },
        { text: '5000以下', value: '5000以下' },
        { text: '5000-10000', value: '5000-10000' },
        { text: '1万以上', value: '1万以上' }
      ],
      showInsurancePicker: false,
      insuranceColumns: [
        { text: '无保险保单', value: '无保险保单' },
        { text: '缴纳未满一年', value: '缴纳未满一年' },
        { text: '缴纳一年以上', value: '缴纳一年以上' }
      ]
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    showArea() {
      this.showAreaPicker = true
    },
    onConfirmArea(values) {
      this.form.provCode = values[0].code
      this.form.provName = values[0].name
      this.form.cityCode = values[1].code
      this.form.cityName = values[1].name
      this.areaText = `${values[0].name} ${values[1].name}`
      this.showAreaPicker = false
    },
    onConfirmIncome(value) {
      this.form.monthlyIncome = value.text
      this.showIncomePicker = false
    },
    onConfirmZhima(value) {
      this.form.zhima = value.text
      this.showZhimaPicker = false
    },
    onConfirmLoan(value) {
      this.form.tencentLoan = value.text
      this.showLoanPicker = false
    },
    onConfirmInsurance(value) {
      this.form.insurance = value.text
      this.showInsurancePicker = false
    },
    async next() {
      if (this.active === 0) {
        if (!this.form.cityCode || !this.form.provCode) {
          this.$toast('请选择所在地区')
          return
        }
        // 校验选项不能为空
        const pageOne = [
          'monthlyIncomeType',
          'monthlyIncome',
          'workedMonth',
          'providentFund',
          'socialSecurity',
          'house',
          'car'
        ]
        for (const key of Object.keys(this.form)) {
          if (pageOne.includes(key) && !this.form[key]) {
            this.$toast('请选择必选项')
            return true
          }
        }
      }
      if (this.active === 1) {
        const pageTwo = [
          'zhima',
          'tencentLoan',
          'insurance',
          'providentFund',
          'socialSecurity',
          'house',
          'car'
        ]
        for (const key of Object.keys(this.form)) {
          if (pageTwo.includes(key) && !this.form[key]) {
            this.$toast('请选择必选项')
            return true
          }
        }
      }
      if (this.active === 2) {
        const pageThree = ['realName', 'idCard', 'applyMoney']
        for (const key of Object.keys(this.form)) {
          if (pageThree.includes(key) && !this.form[key]) {
            this.$toast('请选择必选项')
            return true
          }
        }
        if (!checkNameIsOk(this.form.realName)) {
          return this.$toast('请输入正确的姓名')
        }
        if (!checkIdCardIsOk(this.form.idCard)) {
          return this.$toast('请输入正确的身份证号')
        }
        try {
          this.loading = true
          const res = await saveLoanInfo(this.form)
          if (res.code == 200) {
            this.$toast('个人信息提交成功')
            setTimeout(() => {
              this.$router.push('/home')
            }, 500)
          }
        } catch (error) {
          this.$toast(error.message)
        } finally {
          this.loading = false
        }
        return;
      }
      this.active = this.active === 0 ? 1 : this.active === 1 ? 2 : 0
    }
  }
}
</script>

<style scoped lang="less">
.loan-auth-container {
  min-height: 100vh;
  background: #f7f8fa;
  padding-top: 46px;
  box-sizing: border-box;

  .progress-section {
    background: #fff;
    padding: 20px 16px;
    margin-bottom: 12px;
  }

  .form-container {
    padding: 0 12px;
    margin-bottom: 80px;
  }

  .form-group {
    border-radius: 8px;
    margin-bottom: 12px;
    background: #fff;
  }

  .bottom-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background: #fff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
  }

  :deep(.van-radio-group) {
    display: flex;
    gap: 16px;
  }

  .custom-select {
    text-align: right;
    color: #323233;
    min-height: 24px;

    &:empty::before {
      content: '请选择';
      color: #969799;
    }
  }

  .unit {
    color: #969799;
    padding: 0 8px;
  }

  :deep(.van-field__label) {
    width: 90px;
  }
}
</style>
